* {
  margin: 0;
  padding: 0;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: sans-serif;
  font-size: 16px;
}

.content {
  padding: 20px;
}
.content p {
  margin: 10px 0;
}

button {
  font-size: 18px;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 6px;
  background-color: #eee;
  border: solid 1px #aaa;
  outline: none;
  margin: 5px 5px 5px 0;
}
button:active {
  background-color: #333;
  color: #fff;
}

.form input,
form input {
  font-size: 16px;
  padding: 10px;
  border: solid 1px #aaa;
  outline: none;
  margin: 15px 0;
  border-radius: 6px;
  display: block;
}

.error {
  margin: 10px 0;
  color: crimson;
}

.request-info {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
}
.request-info span {
  margin: 5px 0;
}

.request-info code {
  opacity: 0.5;
}

.Sider {
  @media screen and (max-width: 640px) {
    height: 10rem;
  }
}

/* promot */

.text-pad {
  height: 94px;
  border-radius: 15px;
  font-family: Poppins;
  font-size: 20px;
  color: #7e7e7e;
  
}

.promot {
  height: 94px;
  border-radius: 15px;
  font-family: Poppins;
  font-size: 20px;
  background-color: #f2f2f256;
  color: #7e7e7e;
}

.Midjourney {
  color: #030e32;

  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
}

.Save-your-prompt {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
}
/* 
style={{ backgroundColor: "#12BF80", color: "white" }}
            className="button-login shadow-md " */

.button-copy {
    width: 229px;
    height: 72px;
    font-size: 20px;
    font-family: Poppins;
    border-radius: 7px;
    border: none;
    @media screen and (max-width: 768px) {
      width: 170px;
    height: 52px;
    font-size: 16px;
      
    }
    @media screen and (max-width: 480px) {
      width: 140px;
    height: 42px;
    font-size: 12px;
      
    }
}

